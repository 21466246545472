<template>
  <div class="mobile-search-width">
    <b-button-group>
      <form
        id="searchForm"
        @submit.prevent="searchDataAction"
        class="search_form"
        role="form"
      >
        <button class="btn-search border-radius-l-32" type="submit">
          <b-icon icon="search" variant="black"></b-icon>
        </button>
        <Vue-Simple-Suggest
          @select="searchDataAction"
          :nullable-select="true"
          v-model="searchString"
          :debounce="1000"
          display-attribute="name"
          value-attribute="_id"
          placeholder="Search for Product / Service"
          :list="searchSuggestions"
        ></Vue-Simple-Suggest>
        <button type="reset" class="btnclose rounded-pill text-grey">
          &times;
        </button>
      </form>
    </b-button-group>
  </div>
</template>

<script>
import VueSimpleSuggest from "vue-simple-suggest";
import "vue-simple-suggest/dist/styles.css";
import { searchTextSuggest } from "../services/search";
export default {
  components: {
    VueSimpleSuggest,
  },
  data() {
    return {
      searchString: "",
    };
  },
  computed: {
    filteredSuggestions() {
      return this.searchSuggestions;
    },
  },
  methods: {
    async searchSuggestions(item) {
      if (item && item.length >= 3) {
        try {
          let payload = {
            term: item,
            market: "",
            fields: ["Name", "tags"],
            highlight: {},
            location: {
              city: "Hyderabad",
            },
          };
          if (this.$route.params.market) {
            payload.market = this.$route.params.market;
          }
          const result = await searchTextSuggest(payload);

          if (result.data && result.data.hits.hits.length > 0) {
            const dataArray = [];
            result.data.hits.hits.map((x) => {
              x.highlight["search_suggest._index_prefix"].map((d) =>
                dataArray.push(d)
              );
            });
            return (this.options = Array.from(new Set(dataArray)));
          } else {
            return [];
          }
        } catch (e) {
          return [];
        }
      }
    },
    searchDataAction() {
      setTimeout(() => {
        if (this.searchString.length > 2) {
          let searchString = this.searchString.replace(/[/\\]/g, "");
          this.$router.push({
            name: "SearchProduct",
            params: { id: searchString },
          });
        } else {
          this.$router.push({
            name: "SearchProduct",
            params: { id: this.searchString },
          });
        }
        this.searchString = "";
      }, 500);
    },
  },
  mounted() {},
};
</script>
<style scoped>
.search_form {
  margin-left: 35px;
}
.search_form .btn-search {
  position: absolute;
  top: 0;
  font-size: 18px;
  z-index: 1;
  left: 0;
  width: 45px;
  height: 43px;
  padding: 0;
}
.search_form .btnclose {
  position: absolute;
  top: 0;
  font-size: 24px;
  z-index: 2;
  right: 0;
  width: 45px;
  height: 43px;
  padding: 0;
  background: #fff;
}
.text-grey {
  color: #aaa;
  background-color: transparent;
}
@media (max-width: 768px) {
  .search-mobile-view .search_form {
    margin-left: 40px;
  }
  .search-mobile-view .btn-group {
    display: block;
    width: 100%;
  }
  .search-mobile-view .btn-search {
    left: 0;
  }
  .search-mobile-view .my-4 {
    margin-bottom: 10px !important;
    margin-top: 0 !important;
  }
  .search-mobile-view .search_form button {
    top: 0;
  }
}
</style>
